<template>
  <vue-select
    v-model="selectedOption"
    :placeholder="bottomPlaceholder"
    :options="options"
  >
    <template #search="{ attributes, events }">
      <span class="nameSpan">{{ topPlaceholder }}</span>
      <input class="vs__search" v-bind="attributes" v-on="events">
    </template>
    <template v-slot:no-options="{ searching }">
      <template v-if="searching">
        Ничего не найдено
      </template>
      <em v-else>Ничего не найдено</em>
    </template>
  </vue-select>
</template>

<script>
import VueSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';
import OpenIndicator from './open-indicator.vue';

VueSelect.props.components.default = () => ({ OpenIndicator });

export default {
  components: {
    VueSelect,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['name', 'options', 'topPlaceholder', 'bottomPlaceholder'],
  data: () => ({}),
  computed: {
    selectedOption: {
      get() {
        return this.$store.getters[
          `filters/${this.name.toLowerCase()}SelectedOption`
        ];
      },
      set(val) {
        this.$store.commit(`filters/update${this.name}SelectedOption`, val);
      },
    },
  },
};
</script>

<style lang="scss">
.v-select {
  & * {
    font-style: normal;
    font-size: 14px;
  }

  .vs__dropdown-toggle {
    height: 50px;
    border: 1px solid #dfdfdf;
    border-radius: 0px;
  }

  &.vs--single.vs--searching span.vs__selected {
    display: none !important;
  }

  .vs__selected-options {
    padding: 13px 0 0 9px;
    min-width: 0;
    .vs__selected {
      display: block;
      height: 30px;
      width: 210px;
      line-height: 30px;
      font-family: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $black;
    }
    .vs__search {
      height: 30px;
      width: 210px;
      line-height: 30px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .nameSpan {
    position: absolute;
    top: -2px;
    left: 16px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 30px;
    color: #7e7e7e;
  }

  ul {
    width: 270px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ul > li {
    width: 270px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>

<template>
  <div :class="$style.container">
    <div
      :class="[$style.arrow, { [$style.expanded]: !isOpened }]"
      @click="toggle"
    >
      <img src="@/assets/images/icons/navigation/big-arrow.svg">
    </div>

    <h1 v-show="isOpened">
      ОТОБРАЖЕНИЕ
    </h1>

    <div
      v-show="isOpened"
      :class="$style.customCheckBoxContainer"
      @click="checkAll()"
    >
      <input
        v-model="allChecked"
        type="checkbox"
        :class="$style.customCheckbox"
      >
      <label>
        <check-mark v-if="allChecked" />
        <div :class="[$style.textContainer, $style.checkAlltextContainer]">
          Выбрать все
        </div>
      </label>
    </div>

    <scrollable-container :class="$style.scrollable">
      <tree-menu
        v-for="node in computersTree"
        v-show="isOpened"
        :id="node.id + node.type"
        :key="node.id + node.type"
        :name="getName(node)"
        :nodes="node.children"
        :checked="node.checked"
        :expanded="node.expanded"
        :depth="0"
        :is-folder="node.type === 'folder'"
      />
    </scrollable-container>
  </div>
</template>

<script>
import { getComputers } from '@/api/methods/computers';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import TreeMenu from './tree-menu.vue';
import CheckMark from './check-mark.vue';

export default {
  components: {
    TreeMenu,
    ScrollableContainer,
    CheckMark,
  },
  props: ['text', 'value', 'isOpened'],
  data: () => ({
    tree: [],
    computers: [],
  }),
  computed: {
    allChecked() {
      let res = true;
      const notChecked = this.computersTree.find((computer) => !computer.checked);
      if (notChecked) res = false;
      return res;
    },
    computersTree() {
      return this.$store.getters['pageSpecificData/computersTree'];
    },
    checkedComputerId() {
      return this.$store.getters['pageSpecificData/checkedComputerId'];
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    checkAll() {
      this.$store.commit('pageSpecificData/checkAllComputerNodes', !this.allChecked);
    },
    fetch() {
      return getComputers().then(({ tree, computers }) => {
        this.tree = tree;
        this.computers = computers;
        const computerId = this.checkedComputerId;

        if (!computerId) tree.forEach((item) => this.addChecked(item, false));
        else tree.forEach((item) => this.addChecked(item, computerId));
        this.sendTree();
      });
    },
    toggle() {
      this.$emit('toggle');
    },
    getName(node) {
      if (node.type === 'computer') return node.nameUser;
      return node.name;
    },
    sendTree() {
      this.$store.commit('pageSpecificData/setComputerTree', this.tree);
    },
    addChecked(node, value) {
      if (!value) node.checked = true;
      else if (node.id.toString() === value) {
        node.checked = true;
      } else node.checked = false;
      if (node.type === 'folder') {
        node.expanded = true;
        node.children.forEach((child) => {
          this.addChecked(child, value);
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  height: 100%;
}

.scrollable {
  overflow-x: hidden;
  height: calc(100% - 50px);
}

h1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $black;
  margin-bottom: 20px;
  display: inline-flex;
  margin-left: 20px;
}

.arrow {
  cursor: pointer;
  display: inline-flex;
  transform: rotate(-90deg);
}

.expanded {
  transform: rotate(90deg);
}

.textContainer {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 94px;
  line-height: 21px;
  padding-left: 10px;
  vertical-align: super;
}

.customCheckBoxContainer {
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  input:checked+label>svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }

  label:active::after {
    background-color: #fbfbfb;
  }

  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    display: block;

    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }

  input:checked+label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }

  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }

  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
}

.checkAlltextContainer {
  font-weight: 500;
}
</style>

<template>
  <div>
    <div
      :style="indent"
      :class="$style.customCheckBoxContainer"
      @click="toggleCheckBox()"
    >
      <div
        v-if="isFolder"
        :class="[$style.expandButton, { [$style.expanded]: isExpanded }]"
        @click.stop="isExpanded = !isExpanded"
      />
      <input
        v-model="checked"
        type="checkbox"
        :class="$style.customCheckbox"
      >
      <label>
        <check-mark v-if="checked" />
        <img
          v-if="isFolder"
          :class="$style.iconFolder"
          src="@/assets/images/icons/computer/folder.svg"
        >
        <div :class="$style.textContainer">
          {{ name }}
        </div>
      </label>
    </div>
    <tree-menu
      v-for="node in nodes"
      v-show="isExpanded"
      :id="node.id + node.type"
      :key="node.id + node.type"
      :nodes="node.children"
      :name="getName(node)"
      :depth="depth + 1"
      :checked="node.checked"
      :expanded="node.expanded"
      :is-folder="node.type === 'folder'"
    />
  </div>
</template>

<script>
import CheckMark from './check-mark.vue';

export default {
  name: 'TreeMenu',
  components: {
    CheckMark,
  },
  props: ['name', 'nodes', 'depth', 'checked', 'id', 'isFolder', 'expanded'],
  data: () => ({
    isExpanded: true,
  }),
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 28}px)` };
    },
  },
  created() {
    this.isExpanded = this.expanded;
  },
  methods: {
    toggleCheckBox() {
      this.$store.commit('pageSpecificData/toggleComputerNodeChecked', this.id);
    },
    getName(node) {
      if (node.type === 'computer') return node.nameUser;
      return node.name;
    },
  },
};
</script>

<style lang="scss" module>
.iconFolder {
  margin: 3px 0 6px 10px;
  display: inline-flex;
}

.textContainer {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 94px;
  line-height: 21px;
  padding-left: 10px;
  vertical-align: super;
}

.customCheckBoxContainer {
  display: flex;

  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  input:checked+label>svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }

  label:active::after {
    background-color: #fbfbfb;
  }

  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    display: block;

    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }

  input:checked+label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }

  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }

  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
}

.expandButton {
  flex-shrink: 0;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}
</style>

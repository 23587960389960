<template>
  <div>
    <date-picker
      v-model="dateRange[0]"
      :class="[
        $style.datePicker,
        { [$style.disabled]: disabled }
      ]"
      :disabled="disabled"
      :clearable="clearable"
      @input="dateFromChanged"
    >
      <template v-slot:input>
        <div :class="$style.filter">
          <div :class="$style.dateWrapper">
            <p :class="$style.dateText">
              Дата от
            </p>
            <p
              v-if="dateRange[0]"
              :class="$style.date"
            >
              {{ dateRange[0] | date }}
            </p>
          </div>
        </div>
      </template>
    </date-picker>
    <date-picker
      v-model="dateRange[1]"
      :class="[
        $style.datePicker,
        { [$style.disabled]: disabled }
      ]"
      :disabled="disabled"
      :clearable="clearable"
      @input="dateToChanged"
    >
      <template v-slot:input>
        <div :class="$style.filter">
          <div :class="$style.dateWrapper">
            <p :class="$style.dateText">
              Дата до
            </p>
            <p
              v-if="dateRange[1]"
              :class="$style.date"
            >
              {{ dateRange[1] | date }}
            </p>
          </div>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import dayjs from 'dayjs';
import { date } from '@/helpers/filters';

export default {
  filters: {
    date,
  },
  components: {
    DatePicker,
  },
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateRange() {
      const dateRange = this.$store.getters['filters/dateRange'];
      return [new Date(dateRange[0]), new Date(dateRange[1])];
    },
  },
  methods: {
    dateFromChanged(val) {
      this.$router.replace({
        query: {
          ...this.$router.currentRoute.query,
          dateFrom: dayjs(val).format('YYYY-MM-DD'), // TODO: Унификация формата даты
        },
      });
    },
    dateToChanged(val) {
      this.$router.replace({
        query: {
          ...this.$router.currentRoute.query,
          dateTo: dayjs(val).format('YYYY-MM-DD'), // TODO: Унификация формата даты
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.datePicker {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    background: $light-gray;
  }
}

.datepickerPopup td {
  vertical-align: middle;
}

.filter {
  border: 1px solid #dfdfdf;
  display: flex;
  height: 50px;
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 6px 15px;
  min-width: 107px;
}

.dateText {
  color: #7e7e7e;
  font-size: 12px;
  margin-bottom: 8px;
}

.date {
  font-size: 14px;
}
</style>

<style >
.mx-datepicker {
  cursor: pointer;
  width: 160px;
}
</style>

<template>
  <div>
    <treeselect
      v-model="selectedOption"
      :multiple="true"
      :options="options"
      :placeholder="placeholder"
      :clearable="true"
      :searchable="true"
      :open-on-click="true"
      value-consists-of="LEAF_PRIORITY"
      class="progsTree"
    >
      <label
        slot="option-label"
        slot-scope="{ node, labelClassName }"
        :class="labelClassName"
      >
        <img
          v-if="node.isBranch"
          class="iconFolder"
          src="@/assets/images/icons/computer/folder.svg"
        >
        {{ node.label }}
      </label>
    </treeselect>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getAppsList } from '@/api/methods/reports/users/index';

export default {
  components: {
    Treeselect,
  },
  data: () => ({
    placeholder: 'Выбор программ',
    options: [],
  }),
  computed: {
    selectedOption: {
      get() {
        return this.$store.getters['filters/selectedApps'];
      },
      set(val) {
        this.$store.commit('filters/updateSelectedApps', val);
      },
    },
  },
  created() {
    this.promise = getAppsList().then(({ data }) => {
      this.options = data.reverse();

      return data;
    });
  },
};
</script>

<style lang="scss">
.progsTree .vue-treeselect__control {
  border-radius: 0px;
  height: 50px;
}
.progsTree .vue-treeselect__multi-value {
  max-height: 40px;
  overflow: auto;
  margin: 3px 0px;
}
.progsTree .vue-treeselect__placeholder {
  padding-top: 7px;
}
.progsTree .vue-treeselect__multi-value-item-container {
  padding-top: 3px;
}
.progsTree .iconFolder {
  display: inline-flex;
}
.progsTree .vue-treeselect__checkbox--checked {
  background-color: #2985bf;
  border: 1px solid #2985bf;
}
.progsTree .vue-treeselect__check-mark {
  margin: 0.5px;
}
</style>

var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('date-picker',{class:[
      _vm.$style.datePicker,
      ( _obj = {}, _obj[_vm.$style.disabled] = _vm.disabled, _obj )
    ],attrs:{"disabled":_vm.disabled,"clearable":_vm.clearable},on:{"input":_vm.dateFromChanged},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{class:_vm.$style.filter},[_c('div',{class:_vm.$style.dateWrapper},[_c('p',{class:_vm.$style.dateText},[_vm._v(" Дата от ")]),(_vm.dateRange[0])?_c('p',{class:_vm.$style.date},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.dateRange[0]))+" ")]):_vm._e()])])]},proxy:true}]),model:{value:(_vm.dateRange[0]),callback:function ($$v) {_vm.$set(_vm.dateRange, 0, $$v)},expression:"dateRange[0]"}}),_c('date-picker',{class:[
      _vm.$style.datePicker,
      ( _obj$1 = {}, _obj$1[_vm.$style.disabled] = _vm.disabled, _obj$1 )
    ],attrs:{"disabled":_vm.disabled,"clearable":_vm.clearable},on:{"input":_vm.dateToChanged},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{class:_vm.$style.filter},[_c('div',{class:_vm.$style.dateWrapper},[_c('p',{class:_vm.$style.dateText},[_vm._v(" Дата до ")]),(_vm.dateRange[1])?_c('p',{class:_vm.$style.date},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.dateRange[1]))+" ")]):_vm._e()])])]},proxy:true}]),model:{value:(_vm.dateRange[1]),callback:function ($$v) {_vm.$set(_vm.dateRange, 1, $$v)},expression:"dateRange[1]"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="$style.customCheckBoxContainer" @click="toggleCheckBox()">
    <input
      id="tableGroupCheckbox"
      v-model="checked"
      type="checkbox"
      :class="$style.customCheckbox"
    >
    <label :class="$style.checkboxLabel">
      <check-mark v-if="checked" />
    </label>
    <label :class="$style.textLabel"> Группировка <br>по датам </label>
  </div>
</template>

<script>
import CheckMark from '@/components/common/filter/check-mark.vue';

export default {
  components: {
    CheckMark,
  },
  data: () => ({}),
  computed: {
    checked: {
      get() {
        return this.$store.getters['filters/isTableGroupedByDates'];
      },
      set(val) {
        this.$store.commit('filters/setIsTableGroupedByDates', val);
      },
    },
  },
  methods: {
    toggleCheckBox() {
      this.checked = !this.checked;
    },
  },
};
</script>

<style lang="scss" module>
.customCheckBoxContainer {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  & * {
    cursor: pointer;
  }
  .textLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-left: 10px;
    font-style: normal;
    font-size: 14px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  input:checked + .checkboxLabel > svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }
  .checkboxLabel:active::after {
    background-color: #fbfbfb;
  }
  .checkboxLabel {
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    position: relative;
    display: block;
    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }
  input:checked + .checkboxLabel:after {
    background-color: $blue;
    border: 1px solid $blue;
  }
  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }
  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}
</style>

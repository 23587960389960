<template>
  <layout-navigation>
    <scrollable-container :class="$style.mainContainer">
      <section :class="$style.page">
        <div :class="$style.header">
          <div :class="$style.control">
            <div
              v-if="isDateSelectRequired"
              :class="$style.dateSelect"
            >
              <vue-select
                v-model="selectedDateOption"
                :options="dateOptions"
                :reduce="(option) => option.id"
                label="name"
                :searchable="false"
                :clearable="false"
                @input="setDateOption()"
              />
            </div>

            <store-based-date-range-picker
              :clearable="false"
              :disabled="isDataPickerDisabled"
            />

            <div
              v-if="isPrinterSelectRequired"
              :class="$style.vueSelectContainer"
            >
              <vue-select-custom
                name="Printer"
                top-placeholder="Поиск и выбор принтера"
                bottom-placeholder="Введите название"
                :options="$store.state.filters.selects.printer.options"
              />
            </div>

            <div
              v-if="isComputerSelectRequired"
              :class="$style.vueSelectContainer"
            >
              <vue-select-custom
                name="Computer"
                top-placeholder="Поиск и выбор компьютера"
                bottom-placeholder="Введите название"
                :options="$store.state.filters.selects.computer.options"
              />
            </div>

            <div
              v-if="isTableGroupCheckboxRequired"
              :class="$style.tableGroupCheckboxContainer"
            >
              <table-group-checkbox />
            </div>

            <div
              v-if="isWorktimeOnlyCheckboxRequired"
              :class="$style.worktimeOnlyCheckboxContainer"
            >
              <worktime-only-checkbox />
            </div>

            <div
              v-if="isSwitcherRequired"
              :class="$style.switcher"
            >
              <button
                :class="[$style.switchButton, { [$style.activeSwitchButton]: switcher === 1 }]"
                @click="switchValue(1)"
              >
                Время
              </button>
              <button
                :class="[
                  $style.switchButton,
                  { [$style.activeSwitchButton]: switcher === 2 },
                ]
                "
                @click="switchValue(2)"
              >
                Приход/уход
              </button>
            </div>

            <div
              v-if="isTreeSelectRequired"
              :class="$style.treeSelectContainer"
            >
              <tree-select />
            </div>
          </div>

          <div :class="$style.control" />
          <div :class="[$style.control, $style.buttonsControl]">
            <button
              :class="$style.printButton"
              @click="print"
            >
              Печать
            </button>

            <div :class="[$style.dropdown, $style.buttonContainer]">
              <button :class="[$style.printButton, $style.exportButton]">
                Экспорт в
              </button>
              <div
                v-if="isExportRequired"
                :class="$style.dropdownСontent"
              >
                <div
                  v-for=" option in exportOptions "
                  :key="option"
                  :class="$style.dropdownItem"
                  :v-bind:value="option"
                  @click="exportTable(option)"
                >
                  {{ option }}
                </div>
              </div>
              <div
                v-else
                :class="$style.dropdownСontent"
              >
                <div
                  :class="$style.dropdownItem"
                  @click="exportTable('pdf')"
                >
                  pdf
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <router-view />
    </scrollable-container>
    <container
      v-if="isMultiSelectRequired"
      :class="[$style.multiSelect, { [$style.hidden]: !isOpened }]"
    >
      <multi-select
        :is-opened="isOpened"
        @toggle="toggle"
      />
    </container>
  </layout-navigation>
</template>

<script>
import StoreBasedDateRangePicker from '@/components/common/filter/store-based-separated-date-picker.vue';
import LayoutNavigation from '@/layouts/navigation.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import VueSelectCustom from '@/components/common/header/vue-select-custom/select.vue';
import MultiSelect from '@/components/common/filter/multi-select.vue';
import TableGroupCheckbox from '@/components/common/header/table-group-checkbox.vue';
import WorktimeOnlyCheckbox from '@/components/common/header/worktime-only-checkbox.vue';
import Container from '@/components/common/blocks/container.vue';
import TreeSelect from '@/components/common/header/tree-select.vue';
import html2pdf from 'html-to-pdf-js';
import exportFromJson from 'export-from-json';
import * as dayjs from 'dayjs';
import VueSelect from 'vue-select';

export default {
  components: {
    LayoutNavigation,
    ScrollableContainer,
    VueSelectCustom,
    StoreBasedDateRangePicker,
    MultiSelect,
    TableGroupCheckbox,
    WorktimeOnlyCheckbox,
    Container,
    TreeSelect,
    VueSelect,
  },
  data: () => ({
    isOpened: true,
    switcher: 1,
    exportOptions: ['pdf', 'xls', 'csv'],
    dateOptions: [
      {
        id: 0,
        name: 'Неделя',
      },
      {
        id: 1,
        name: 'Месяц',
      },
      {
        id: 2,
        name: 'Год',
      },
      {
        id: 3,
        name: 'Выбрать вручную',
      },
    ],
    selectedDateOption: 3,
  }),
  computed: {
    isDateSelectRequired() {
      return this.$route.meta.requiredStoreParameters.dateSelect === true;
    },
    isDataPickerDisabled() {
      if (this.isDateSelectRequired && this.selectedDateOption !== 3) return true;
      return false;
    },
    isPrinterSelectRequired() {
      return this.$route.meta.requiredStoreParameters.printer === true;
    },
    isComputerSelectRequired() {
      return this.$route.meta.requiredStoreParameters.computer === true;
    },
    isMultiSelectRequired() {
      return this.$route.meta.requiredStoreParameters.multiSelect === true;
    },
    isTableGroupCheckboxRequired() {
      return this.$route.meta.requiredStoreParameters.tableGroup === true;
    },
    isWorktimeOnlyCheckboxRequired() {
      return this.$route.meta.requiredStoreParameters.worktimeOnly === true;
    },
    isSwitcherRequired() {
      return this.$route.meta.requiredStoreParameters.switcher === true;
    },
    isTreeSelectRequired() {
      return this.$route.meta.requiredStoreParameters.treeSelect === true;
    },
    isExportRequired() {
      return this.$route.meta.requiredStoreParameters.export === true;
    },
  },
  methods: {
    setDateOption() {
      this.$store.commit('filters/setDateSelect', this.selectedDateOption);
    },
    switchValue(value) {
      this.switcher = value;
      this.$store.commit('filters/setSwitcher', value);
    },
    toggle() {
      this.isOpened = !this.isOpened;
    },
    rftcharcode(c) {
      const cc = c.charCodeAt();
      return cc <= 32767 ? cc : cc - 65535;
    },
    async exportTable(type) {
      let filename = `${this.$route.name} - ${this.$route.query.dateFrom} - ${this.$route.query.dateTo}`;
      const data = this.$store.getters[`exportData/${this.$route.name}`];

      if (type === 'pdf') {
        this.$store.commit('loader/setIsLoaderNeeded', true);

        const target = document.getElementById('printable');
        const copy = target.cloneNode(true);
        const prtHtml = copy.innerHTML;

        let stylesHtml = '';
        [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ].forEach((node) => {
          stylesHtml += node.outerHTML;
        });
        stylesHtml
          += '<style type="text/css">html{width:21cm;height:29.7cm;}body{display:block!important;}</style>';

        const dateRange = this.$store.getters['filters/formattedDateRange'];
        const dateFrom = dayjs(dateRange.dateFrom).format('DD.MM.YY');
        const dateTo = dayjs(dateRange.dateTo).format('DD.MM.YY');

        const element = `<!DOCTYPE html>
                              <html>
                                <head>
                                  ${stylesHtml}
                                </head>
                                <body>
                                <div style="margin:5px 5px 15px 5px;display:flex;justify-content:center">  
                                  ${data.name} c: ${dateFrom} по: ${dateTo} </div>
                                  ${prtHtml}
                                </body>
                              </html>`;

        let orientation = 'portrait';
        const thead = copy.getElementsByTagName('thead');
        if (thead.length > 0) {
          const tdArray = thead[0].children[0].children;

          if (tdArray && tdArray.length > 8) orientation = 'landscape';
        }

        const opt = {
          margin: 10,
          filename: `${filename}.pdf`,
          pagebreak: { mode: ['avoid-all', 'css'] },
          jsPDF: { orientation },
          html2canvas: { scale: 4 },
        };

        if (this.$route.name === 'activityFeed' || this.$route.name === 'worktimeDetails'
          || this.$route.name === 'productivity') {
          opt.html2canvas.scale = 3;

          let pages = [];

          if (this.$route.name === 'activityFeed') {
            // eslint-disable-next-line
            pages = element.split(/(?=<div class=\"activity-table_toggle)/);
          } else if (this.$route.name === 'worktimeDetails') {
            // eslint-disable-next-line
            pages = element.split(/(?=<div class=\"worktime-report_userStickyToggle)/);
          } else if (this.$route.name === 'productivity') {
            // eslint-disable-next-line
            pages = element.split(/(?=<div class=\"productivity-report_userStickyToggle)/);
          }

          if (pages[1]) {
            pages[1] = pages[0] + pages[1];
            pages.shift();
          }

          let worker = html2pdf().set(opt).from(pages[0]).toPdf();
          pages.slice(1).forEach((page) => {
            worker = worker.get('pdf').then((pdf) => {
              pdf.addPage();
            }).from(page).toContainer()
              .toCanvas()
              .toPdf();
          });
          await worker.save();
        } else await html2pdf().set(opt).from(element).save();

        this.$store.commit('loader/setIsLoaderNeeded', false);
      } else {
        /* if (type === 'rtf') {
           const target = document.getElementById('printable');

           filename += '.rtf';
           const html = target.outerHTML;
           const rtfContent = htmlToRtf.convertHtmlToRtf(html);
           const self = this;

           // eslint-disable-next-line
           const content = rtfContent.replace(/[^\x00-\x7F]/g, (c) => `\\u${self.rftcharcode(c)}?`);
           const type = 'application/rtf'

           const file = new Blob([content], { type });
           const downloadLink = document.createElement('a');
           downloadLink.download = filename;
           downloadLink.href = window.URL.createObjectURL(file);
           downloadLink.style.display = 'none';
           document.body.appendChild(downloadLink);
           downloadLink.click();
         }
         else { */
        filename += '.xls';

        exportFromJson({
          data: data.data, fileName: filename, exportType: type,
        });
      }
    },

    print() {
      const prtHtml = document.getElementById('printable').innerHTML;

      let stylesHtml = '';
      [...document.querySelectorAll('link[rel="stylesheet"], style')].forEach(
        (node) => {
          stylesHtml += node.outerHTML;
        },
      );
      stylesHtml
        += '<style type="text/css">html{width:21cm;height:29.7cm;}body{display:block!important;}</style>';

      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=yes,status=yes',
      );

      WinPrint.document.write(`<!DOCTYPE html>
                              <html>
                                <head>
                                  ${stylesHtml}
                                </head>
                                <body>
                                  ${prtHtml}
                                </body>
                              </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style lang="scss" module>
.mainContainer {
  background: $white;
  box-shadow: $shadow;
  flex-grow: 1;
}

.header {
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 19px;
}

.control {
  display: flex;
  align-items: center;
  margin: 0px 5px;
}

.buttonsControl {
  @media (max-width: 1400px) {
    flex-direction: column;
    gap: 5px;
  }
}

.vueSelectContainer {
  width: 270px;
  height: 50px;
  margin-left: 20px;
}

.multiSelect {
  min-width: 250px;
  margin-left: 20px;
  background-color: $white;
  padding: 20px;
  overflow: hidden;
}

.hidden {
  min-width: 62px;
}

.tableGroupCheckboxContainer {
  width: 132px;
  height: 50px;
  margin-left: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 0px;
}

.worktimeOnlyCheckboxContainer {
  width: 152px;
  height: 50px;
  margin: 0px 7px 0px 7px;
  border: 1px solid #dfdfdf;
  border-radius: 0px;
}

.printButton {
  cursor: pointer;
  display: block;

  width: 95px;
  height: 28px;
  float: right;

  background: $blue;
  border: 2px solid $blue;
  border-radius: 2px;

  font: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: $white;

  margin: 0px 5px;
}

.treeSelectContainer {
  margin: 0px 7px;
  width: 45%;
  min-width: 170px;
}

.exportButton {
  background: white;
  color: #2985bf;
}

.dropdown {
  position: relative;
}

.dropdownСontent {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  z-index: 50;
  top: 28px;
  width: 95px;
  font-size: 14px;
  left: 5px;
}

.dropdownItem {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdownСontent .dropdownItem:hover {
  background-color: #eee;
}

.dropdown:hover .dropdownСontent {
  display: block;
}

.switchButton {
  height: 48px;
  cursor: pointer;
  width: 120px;
  background: #bfcbd9;
  border: 0px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;

  &:hover {
    background: $dark-gray;
    transition: background-color 0.5s linear;
  }

  &:disabled {
    background: $branchColor;
  }
}

.activeSwitchButton {
  background: #2985BF;
}

.switcher {
  margin: 20px;
}

.dateSelect {
  width: 200px;
}
</style>

<style>
.v-select .vs__selected-options {
  padding: 6px 0 0 9px;
}

.v-select ul {
  width: 100%;
}
</style>
